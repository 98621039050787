import { graphql } from 'gatsby';
import React from 'react';

import { getImage } from '../../../../utils/sanityTypes';
import HeroWithPosts from '../../../organisms/HeroWithPosts';
import { IHeroPostTeaserItemProps } from '../../../organisms/HeroWithPosts/types';
import { HeroWithPostsSectionProps } from './types';

const HeroWithPostsSection: React.VFC<HeroWithPostsSectionProps> = ({
  scrollButtonLabel,
  teaserButtonLabel,
  posts,
}) => (
  <HeroWithPosts
    data={{
      scrollButtonLabel: scrollButtonLabel ?? '',
      teaserButtonLabel: teaserButtonLabel ?? 'Read the whole story',
      posts: (posts ?? []).map<IHeroPostTeaserItemProps>((post) => {
        const {
          key,
          subtitle,
          title,
          slug,
          publishDate,
          firstName,
          lastName,
          images,
          hasTextBgOverlay,
        } = post ?? {};

        return {
          key: key ?? '',
          title: title ?? '',
          link: { to: slug?.current ?? '' },
          subtitle: subtitle ?? '',
          lastName: lastName ?? '',
          firstName: firstName ?? '',
          publishDate: publishDate ?? '',
          images: (images ?? []).map((singleImage) => {
            const { mobileImage, image, _key: imageKey } = singleImage ?? {};

            return {
              key: imageKey ?? '',
              image: getImage(image),
              mobileImage: getImage(mobileImage),
            };
          }),
          hasTextBgOverlay: hasTextBgOverlay ?? false,
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment HeroWithPostsSectionFragment on SanityHeroWithPostsSection {
    _key
    _type
    scrollButtonLabel
    teaserButtonLabel
    posts {
      key: id
      title
      subtitle
      publishDate(formatString: "MMMM d, yyyy")
      firstName
      lastName
      images {
        _key
        image {
          ...ImageFragment
        }
        mobileImage {
          ...ImageFragment
        }
      }
      slug {
        current
      }
      hasTextBgOverlay
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default HeroWithPostsSection;
