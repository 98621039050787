import styled, { css } from 'styled-components';

import { Inner, innerStyles, FlexBox } from '../../styles/Grid/styles';
import Link from '../../atoms/Link';
import { HeadingTitle, HeadingSubtitle } from '../../molecules/Heading/styles';
import { RichTextContainer } from '../../molecules/RichText/styles';
import media from '../../../common/MediaQueries';
import { HeroImageStyledProps } from './types';

export const Hero = styled(FlexBox)`
  position: relative;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;

  @media ${media.phone} {
    height: 100vh;
  }

  .slick-dots {
    ${innerStyles}
    left: 0;
    right: 0;
    position: absolute;
    justify-content: flex-start;
    height: 0;
    transform: translateY(-4rem);
    z-index: 1;
  }
`;

export const HeroInner = styled(Inner)`
  z-index: 1;
  position: relative;
  display: flex;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;

  min-height: 100vh;
  width: 100%;

  @media ${media.phone} {
    height: 100vh;
  }

  @media ${media.maxTablet} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

export const HeroMediaBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;

  .slick-slide,
  .slick-slide > div,
  .slick-slide img,
  .slick-track,
  .slick-list,
  .slick-slider {
    height: 100%;
    width: 100vw !important;
    max-width: 100vw;
    min-width: 100vw;
  }

  .slick-track {
    display: flex !important;
  }

  @media ${media.maxPhone} {
    .slick-slider:not([dir='ltr']) .slick-track {
      max-width: 100%;
    }
  }
`;

export const HeroSlide = styled.div`
  height: 100%;
  min-height: 100vh;
`;

export const HeroImage = styled.div<HeroImageStyledProps>`
  height: 100%;

  ${({ hasMobilePicture }) =>
    hasMobilePicture &&
    css`
      @media ${media.maxPhone} {
        display: none;
      }
    `}
`;

export const HeroImageMobile = styled.div`
  height: 100%;

  @media ${media.maxPhone} {
    display: block;
  }

  @media ${media.phone} {
    display: none;
  }
`;

export const HeroScroll = styled.div`
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
  width: 1.125rem;
  height: 8.75rem;
  z-index: 1;

  @media ${media.phone} {
    bottom: 3rem;
  }
`;

export const HeroScrollInner = styled.div`
  transform: rotate(90deg) translate(-3.75rem, 3.625rem);
  height: 100%;
`;

export const HeroOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  ${({ theme }) => theme.overlays.radial70}
`;

export const HeroAction = styled(Link)`
  margin-top: 2rem;
`;

export const HeroInfoList = styled.ul`
  margin-top: 1rem;

  @media ${media.phone} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const HeroInfoListItem = styled.li`
  &:not(:first-child) {
    margin-top: 1rem;
  }

  @media ${media.phone} {
    width: 50%;
    max-width: 17.75rem;
    &:not(:first-child) {
      margin-top: 0;
    }
    &:nth-child(odd) {
      padding-right: 2rem;
    }
  }
`;

export const HeroContent = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  max-width: 41rem;
  margin: auto;
  text-align: center;
  z-index: 1;

  ${HeadingTitle} {
    color: ${({ theme }) => theme.colors.neutral.neutral10};
  }
  ${HeadingSubtitle} {
    color: ${({ theme }) => theme.colors.additional.accentGray3};
  }

  @media ${media.phone} {
    ${HeadingTitle} {
      ${({ theme }) => theme.headingXL};
    }
  }

  @media ${media.tablet} {
    ${HeadingTitle} {
      ${({ theme }) => theme.headingXXL};
    }
  }
`;

export const HeroContentInner = styled.div`
  color: ${({ theme }) => theme.colors.neutral.neutral10};

  @media ${media.maxPhone} {
    padding-top: 2rem;
  }

  @media ${media.maxTablet} {
    text-align: center;
  }

  ${RichTextContainer} {
    color: ${({ theme }) => theme.colors.neutral.neutral10};
  }
`;

export const HeroTabs = styled.div`
  margin: 2rem auto 0;
  width: 100%;
  max-width: 24.625rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.additional.accentGray3};

  @media ${media.phone} {
    margin: 0 0 0 3rem;
  }

  @media ${media.tablet} {
    margin-left: 6rem;
  }
`;
