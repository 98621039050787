import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import * as Styled from './styles';
import { HeroWithPostsProps } from './types';
import { settings } from './const';
import { useScrollTrigger } from './hooks';

import Image from '../../atoms/Image';
import Heading from '../../molecules/Heading';
import ScrollTrigger from '../../atoms/ScrollTrigger';
import { LinkMode } from '../../atoms/Link/enums';
import Typography from '../../styles/Typography';

const HeroWithPosts: React.FC<HeroWithPostsProps> = ({ data }) => {
  const { scrollButtonLabel, teaserButtonLabel, posts } = data;
  const { ref, onScrollClick } = useScrollTrigger();

  return (
    <Styled.Hero {...{ ref }}>
      <Slider {...settings}>
        {posts.map(
          ({
            key,
            subtitle,
            title,
            link,
            publishDate,
            firstName,
            lastName,
            images,
            hasTextBgOverlay,
          }) => (
            <Styled.HeroSlide {...{ key }}>
              <Styled.HeroMediaBox>
                {hasTextBgOverlay && <Styled.HeroOverlay />}
                <Styled.HeroImage hasMobilePicture={!!images[0].mobileImage?.image}>
                  <Image loading="eager" {...images[0].image} />
                </Styled.HeroImage>
                {!!images[0].mobileImage?.image && (
                  <Styled.HeroImageMobile>
                    <Image loading="eager" {...images[0].mobileImage} />
                  </Styled.HeroImageMobile>
                )}
              </Styled.HeroMediaBox>
              <Styled.HeroInner>
                <Styled.HeroContent>
                  <Styled.HeroContentInner>
                    <Heading
                      {...{ title, subtitle }}
                      content={
                        <Typography as="span" variant="textM">
                          by{' '}
                          <strong>
                            {firstName} {lastName}
                          </strong>{' '}
                          {publishDate}
                        </Typography>
                      }
                    />
                    {link.to && (
                      <Styled.HeroAction mode={LinkMode.HasBorder} {...link}>
                        {teaserButtonLabel}
                      </Styled.HeroAction>
                    )}
                  </Styled.HeroContentInner>
                </Styled.HeroContent>
                <Styled.HeroScroll>
                  <Styled.HeroScrollInner>
                    <ScrollTrigger label={scrollButtonLabel} onClick={onScrollClick} />
                  </Styled.HeroScrollInner>
                </Styled.HeroScroll>
              </Styled.HeroInner>
            </Styled.HeroSlide>
          )
        )}
      </Slider>
    </Styled.Hero>
  );
};

export default HeroWithPosts;
